


















































































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import RequisitionItemForm from '@/components/RequisitionItemForm.vue'
import VehicleForm from '@/components/VehicleForm.vue'
import RequisitionItemMedia from '@/components/RequisitionItemMedia.vue'
import DocumentsList from '@/components/DocumentsList.vue'
import DocumentsUpload from '@/components/DocumentsUpload.vue'
import DocumentGeneration from '@/components/DocumentGeneration.vue'
import HighlightIcon from '@/components/Icons/HighlightIcon.vue'
import SivForm from '@/components/SivForm.vue'
import {
  ApiResponse,
  ItemFile,
  RequisitionItem,
  Sale,
  RelatedSaleItem,
  Structure,
  Document,
  CarDefect,
} from '@/interfaces'
import { mapState } from 'vuex'
import { stockStatuses } from '@/formHelpers'

interface Badge {
  color: string
  value: string
  textColor: string
}

interface FileList {
  itemId: number
  itemFiles: string[]
}

interface TabObject {
  _props: Record<string, string>
}

interface RequisitionProject {
  id?: number | string | null
  generatedNumber?: string | null
  structure?: Structure | null
}

export default Vue.extend({
  components: {
    Nav,
    RequisitionItemForm,
    VehicleForm,
    RequisitionItemMedia,
    HighlightIcon,
    SivForm,
    DocumentsList,
    DocumentsUpload,
    DocumentGeneration,
  },
  data() {
    return {
      //hasVehicleRole: false,
      displayAlert: false,
      project: {} as RequisitionProject,
      item: {
        startingPrice: null,
        estimationLow: null,
        estimationHigh: null,
        endPrice: null,
        requisitionItemFiles: [],
        vehicule: false,
      } as RequisitionItem,
      itemId: null as string | null,
      itemDescription: null as string | null,
      itemEstimationLow: null as string | null,
      itemEstimationHigh: null as string | null,
      itemStartingPrice: null as string | null,
      busy: false,
      saveRequisitionTrigger: false,
      mediaList: [] as FileList[],
      coverPhoto: null as string | null,
      activeTab: 'infos',
      stockStatuses,
      relatedItemNumber: null as number | null,
      relatedSaleItem: null as RelatedSaleItem | null,
      projectId: null as null | string,
      documents: [] as Document[],
      docToUpdate: null as Document | null,
      percentage: 0,
      addons: 0,
    }
  },
  computed: {
    ...mapState(['user', 'scrollPosition']),
    itemTitle(): string {
      if (this.itemDescription) {
        if (this.itemDescription?.length > 65) {
          return this.itemDescription.substring(0, 65) + '...'
        } else {
          return this.itemDescription
        }
      } else {
        return ''
      }
    },
    startingPrice(): string {
      return this.itemStartingPrice !== null ? `${this.itemStartingPrice} €` : ''
    },
    estimationPrice(): string {
      let estimationPrice = ''

      if (this.item.estimationLow !== null) {
        estimationPrice = `${this.itemEstimationLow} €`
      }

      if (this.item.estimationHigh !== null) {
        estimationPrice = estimationPrice + ` - ${this.itemEstimationHigh} €`
      }

      return estimationPrice
    },
    endPrice(): string {
      return this.item.endPrice !== null ? `${this.item.endPrice} €` : ''
    },
    acquereurName(): string {
      return this.item.requisition?.debiteur?.formName ? `- ${this.item.requisition?.debiteur?.formName}` : ''
    },
  },
  watch: {
    user(newVal) {
      //this.hasVehicleRole = newVal && newVal.roles.includes('ROLE_VEHICLE')
      if (newVal) {
        this.addons = newVal.client?.addons ?? 0
      }
    },
    item(newVal) {
      this.refreshCoverPhoto(newVal.requisitionItemFiles)
      this.relatedItemNumber = newVal.id ?? null
      this.relatedSaleItem = newVal.relatedSaleItems.find((relatedSaleItem: RelatedSaleItem) => {
        return newVal.id === relatedSaleItem.id
      })?.id
      this.itemId = newVal.id.toString()
      this.percentage = this.getSaleDelayPercentage()
    },
  },
  mounted() {
    if (this.user) {
      //this.hasVehicleRole = this.user && this.user?.roles.includes('ROLE_VEHICLE')
      this.addons = this.user.client?.addons ?? 0
    }
    if (this.$route.query.tab && this.$route.query.tab !== null) {
      this.activeTab = this.$route.query.tab as string
    }
  },
  methods: {
    updateData(item: RequisitionItem) {
      this.itemDescription = item.description ?? null
      this.itemEstimationLow = item.estimationLow ?? null
      this.itemEstimationHigh = item.estimationHigh ?? null
      this.itemStartingPrice = item.startingPrice ?? null
    },
    editRequisition() {
      this.$router.push({
        name: 'requisition_edit',
        params: {
          requisitionId: this.item.requisition?.id?.toString() ?? '',
        },
      })
    },
    editSale(sale: Sale) {
      this.$router.push({
        name: 'sale_edit',
        params: { saleId: sale.id?.toString() ?? '' },
        query: { tab: 'item' },
      })
    },
    loadRequisitionItem() {
      this.busy = true
      this.displayAlert = false

      this.$api
        .get(`/requisitionitems/${this.$route.params.itemId}`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.item = apiResponse.data
          if (this.item.requisition) {
            this.project = this.item.requisition.project as RequisitionProject
          }
        })
        .catch(() => {
          this.displayAlert = true
        })
        .finally(() => {
          this.busy = false

          window.scrollTo({
            top: parseInt(this.$route.query.scrollTo ? this.$route.query.scrollTo.toString() : '0'),
          })
        })
    },
    changeTab(tab: TabObject) {
      if (!this.busy) {
        this.activeTab = tab._props.name ?? 'infos'
        // Here we use pushState as using Vue's router would trigger a page reload
        history.pushState(null, '', `${this.$route.path}?tab=${this.activeTab}`)

        this.triggerSave()
        // plus simple : traiter dans le parent le test de l'active tab
        // plutot que le de passer a l'enfant pour qu'il appelle une fonction du
        // parent pour charger la liste des docs
        switch (this.activeTab) {
          case 'documents':
            this.getDocuments()
            break
        }
      }
    },
    triggerSave() {
      this.saveRequisitionTrigger = !this.saveRequisitionTrigger

      return true
    },
    goBack() {
      this.triggerSave()
      this.$router.back()
    },
    itemUpdated(item: RequisitionItem) {
      this.item = item
    },
    projectUpdated(project: RequisitionProject) {
      this.project = project
    },
    deleteRequisitionItem(item: RequisitionItem) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer ce lot ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/requisition/${this.item.requisition?.id?.toString() ?? ''}/item/${item.id}`)
          .then(() => {
            this.$router.push({
              name: 'requisition_edit',
              params: {
                requisitionId: this.item.requisition?.id?.toString() ?? '',
              },
            })
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    refreshCoverPhoto(itemFiles: ItemFile[]) {
      const photos = this.cleanRequisitionItemFiles(itemFiles)
      if (photos.length > 0) {
        this.coverPhoto = photos[0].url
      } else {
        this.coverPhoto = null
      }

      this.$set(this.item, 'requisitionItemFiles', photos)

      // Updating vehicle defects, if there are any, to reflect with images changes
      if (this.item.vehicle?.defects !== null) {
        const savedDefects = [] as CarDefect[]
        this.item.vehicle?.defects?.forEach((defect) => {
          let savedMediafile = null
          itemFiles.forEach((itemFile) => {
            if (itemFile.accessToken == defect.mediafile?.accessToken) {
              savedMediafile = defect.mediafile
            }
          })
          savedDefects.push({
            part: defect.part,
            type: defect.type,
            mediafile: savedMediafile,
          })
        })

        this.$set(this.item, 'vehicle', { ...this.item.vehicle, defects: savedDefects })
      }
    },
    cleanRequisitionItemFiles(files: ItemFile[]) {
      return files.filter(this.cleanDeletedFiles).sort(this.sortByPosition)
    },
    cleanDeletedFiles(file: ItemFile) {
      return file.deletedAt == null
    },
    sortByPosition(file1: ItemFile, file2: ItemFile) {
      if (file1.position > file2.position) {
        return 1
      }

      if (file1.position < file2.position) {
        return -1
      }

      return 0
    },
    getStatusBadge(status: string): Badge {
      const badge = {} as Badge

      badge.color = this.stockStatuses.find((stockStatus) => stockStatus.value === status)?.color ?? '#EFE6FD'
      badge.textColor = this.stockStatuses.find((stockStatus) => stockStatus.value === status)?.textColor ?? '#333333'
      badge.value = this.stockStatuses.find((stockStatus) => stockStatus.value === status)?.label ?? '-'

      return badge
    },
    duplicateRequisitionItem(item: RequisitionItem) {
      if (item) {
        this.$confirm('Êtes-vous sûr(e) de vouloir dupliquer ce lot ?', 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }).then(() => {
          const loading = this.$loading({
            target: '#requisitionItemsEdit',
            text: 'Duplication en cours...',
          })
          this.$api
            .post(`/requisition/${this.project.id}/item/${item.id}/duplicate`)
            .then((response) => {
              const apiResponse = response.data as ApiResponse
              const newItem = apiResponse.data as RequisitionItem
              if (newItem.id) {
                this.goToRequisitionItem(newItem.id)
              }
            })
            .catch(() => {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
              })
            })
            .finally(() => {
              loading.close()
            })
        })
      }
    },
    detachItemFromSale() {
      this.$confirm(
        'Êtes-vous sûr(e) de retirer ce lot de cette vente ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$set(this.item, 'sale', null)
        this.triggerSave()
      })
    },
    goToRequisitionItem(itemId: number) {
      this.$router.push({
        name: 'requisition_item_edit',
        params: {
          itemId: itemId.toString() ?? '',
        },
        query: {
          tab: this.activeTab,
        },
      })
    },
    goToSaleItem(itemId: number) {
      const item = this.item.relatedSaleItems?.find((item: RelatedSaleItem) => item.id === itemId)
      if (item) {
        this.$router.push({
          name: 'requisition_item_edit',
          params: {
            itemId: item.id?.toString() ?? '',
          },
          query: {
            tab: this.activeTab,
          },
        })
      }
    },
    formatItemNumber(itemId: string) {
      return `Stock N°${itemId}`
    },
    formatSaleItemNumber(itemId: string) {
      return `Lot N°${itemId}`
    },
    getDocuments() {
      if (this.item?.id !== null) {
        this.$api(`/requisitionitems/${this.item.id}/documents`).then((response) => {
          const apiResponse = response.data as ApiResponse
          this.documents = apiResponse.data
        })
      }
    },
    // updateDocuments(documents: Document[]) {
    //   this.documents = documents
    // },
    refreshDocuments(documents: Document[]) {
      //this.updateDocuments(documents)
      // Les appels sur chaque doc ne sont pas sensé renvoyer une liste mais le doc mis à jour
      // l'appel de refresh s'occupe donc de recharger la liste si besoin.
      //this.documents = documents
      this.getDocuments()
      this.docToUpdate = null
    },
    updateDoc(document: Document) {
      this.docToUpdate = document
        ; (this.$refs.uploadDoc as InstanceType<typeof DocumentsUpload>).addFile()
    },
    goToDocs(tab: string) {
      this.activeTab = tab
      // Here we use pushState as using Vue's router would trigger a page reload
      history.pushState(null, '', `${this.$route.path}?tab=${this.activeTab}`)
    },
    getSaleDelay() {
      const today = new Date()
      const timeline = this.item.timeline
      let firstStatus = null
      let firstDate = null
      let soldDate = null as null | Date
      let delay = 0

      if (timeline !== null && Array.isArray(timeline) && timeline.length > 0) {
        firstStatus = timeline[0].timestamp

        timeline.forEach((timelineItem) => {
          if (timelineItem.content === 'sold') {
            soldDate = new Date(timelineItem.timestamp)
          }
        })
      }

      if (firstStatus !== null) {
        firstDate = new Date(firstStatus)
      } else {
        firstDate = today
      }

      if (soldDate !== null) {
        delay = soldDate.getTime() - firstDate.getTime()
      } else {
        delay = today.getTime() - firstDate.getTime()
      }

      // Delay expressed in days
      return delay > 0 ? Math.ceil(delay / (1000 * 3600 * 24)) : 0
    },
    getSaleDelayPercentage() {
      const delay = this.getSaleDelay()

      if (delay == 0) {
        return 0
      } else {
        // Please note that 15 is an arbitrary number
        const limit = 15
        return delay >= limit ? 100 : Math.round((delay * 100) / limit)
      }
    },
    getPricePerformance() {
      let performance = '-' as string | number
      let est = isNaN(parseInt(this.item.estimationLow ?? '')) ? null : parseInt(this.item.estimationLow ?? '')
      const end = isNaN(parseInt(this.item.endPrice ?? '')) ? null : parseInt(this.item.endPrice ?? '')

      if (this.item.vehicule == true) {
        est = isNaN(parseInt(this.item.vehicle?.marketValue ?? ''))
          ? null
          : parseInt(this.item.vehicle?.marketValue ?? '')
      }

      if (est !== null && end !== null && est !== 0) {
        performance = Math.round((end * 100) / est)
      }

      return performance
    },
    getPrice(status: string) {
      if (status == "sold" || status == 'removed' || status == 'amicably_sold' || status == 'to_buyer_back') {
        return `${this.item.endPrice} €`
      }else{
        return ""
      }  
    },    
    setDisplayAlert(displayAlert: false) {
      this.displayAlert = displayAlert
    },
  },
})
